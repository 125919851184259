import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import config, { apiUrl } from '../config';

const Session = () => {
  const opts = config.defaultFetchOpts;
  opts.credentials = 'include';

  const { data } = useQuery('adenin-session-v2', () => fetch(`${apiUrl}/session/myprofile`, opts).then(async (res) => res.json()), {
    cacheTime: 0,
    refetchOnWindowFocus: false,
    enabled: true,
  });

  const [sessionJSON, setSessionJSON] = useState(JSON.stringify({}));

  useEffect(() => {
    if (!data) return;
    setSessionJSON(JSON.stringify(data));
  }, [data]);

  return <div>{sessionJSON}</div>;
};

export default Session;
